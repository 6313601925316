.loading-icon{
    display: block;
    width: 1em;
    height: 1em;
    margin-inline: auto;
    border: 1.5px solid gray;
    border-bottom-color: transparent;
    background-color: transparent;
    border-radius: 50%;
    animation: loadingIconRotate .8s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
@keyframes loadingIconRotate{
    to{
        transform: rotate(360deg);
    }
}