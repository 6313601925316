.footer{
    width: 100%;
    background-color: var(--black);
    padding-block: 1em;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: .4em;
}
.footer .icons-row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
    width: max-content;
    justify-content: space-between;
    margin-inline: auto;
}
.footer .icons-row a{
    font-size: 1.2em;
    line-height: 1;
    color: var(--white);
    border-radius: 50%;
    padding: .5em;
    width: max-content;
}
.footer p{
    text-align: center;
    font-size: .9em;
}
.footer p a{
    color: var(--red);
    text-transform: uppercase;
    font-weight: 500;
    text-decoration: none;
}