*,*::after,*::before{
  margin: 0;padding: 0;
  box-sizing: border-box;
  -webkit-backface-visibility: hidden;
}
a,button{
  user-select: none;
  text-decoration: none;
}
::selection{
  background-color:rgb(26, 26, 26);
  color: rgba(172, 172, 172, .6);
}

html{
  --red: #d50406;
  --alert: #fff10b;
  --black: rgb(10, 10, 10);
  --white: rgb(235, 232, 232);
  --dark-white: rgb(227, 227, 227);
  --gray: rgb(27, 32, 39);
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root{
  width: 100%;
}