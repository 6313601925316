.glounge-logo{
    height: auto;
margin: 1em auto;
width: 5em;
filter: drop-shadow(0 0 15px var(--red));
display: block;
}

.glounge-logo path{
    fill: transparent !important;
    stroke: var(--red);
    stroke-width: 11px;
}