.home-page{
    background-color: var(--black);
    height: max-content;
    width: 100%;
    color: var(--white);
}
.hero{
    display: flex;
    width: 100%;
    /* padding-left: 1.5rem; */
    justify-content: space-between;
    height: max-content;
    min-height: 100vh;
    flex-direction: column;
    background-image: url('../../../public/G-Lounge bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
}
.hero .cols{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 1.5rem;
    justify-content: space-between;
    margin-top: 1rem;
}
.hero .subheader{
    font-weight: 500;
    font-size: 3em;
    width: 6ch;
    text-align: left;
    margin-top: 1rem;
    color: var(--white);
}
.hero a{
    margin-inline: 0;
}
.hero .cols .column:first-child{
    backdrop-filter: blur(17px);
    border-radius: .7em;
    width: 34%;
    min-width: max-content;
    overflow: hidden;
    background-color: rgba(255, 255, 255, .02);
    padding: .5em;
    box-shadow: 0 0 1px 0.5px rgba(255, 255, 255, .6);
}

.activate-link{
    text-decoration: underline;
    margin-block: 1em .5em;
    display: block;
    color: var(--red);
    font-size: .8em;
}

@media (max-width: 820px){
    .activate-link{
    margin-block: 1em;
    }
    .hero .cols{
        padding-left: 0;
    }
    .hero .cols .column:first-child{
        margin-inline: auto;
    }
    .hero{
        padding-left: 0;
    }
    .hero .subheader{
        font-size: 2.8em;
        width: 9ch;
    }
    .hero a{
        width: 100%;
        margin-inline: 0;
        text-align: center;
    }
}