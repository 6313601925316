.user-profile{
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    color: var(--white);
}
.user-card{
    display: flex;
    margin-block: 1em;
    flex-direction: column;
    text-align: left;
    width: 20em;
    padding: .5em;
}
.user-card h3{
    font-size: 1em;
    opacity: .8;
    line-height: 1.6;
    font-weight: 400;
}
.user-card h2{
    font-weight: 500;
}

.reservation{
    margin-top: 3em;
    width: 20em;
    overflow: hidden;
    position: relative;
    height: max-content;
    padding: .8em;
    border-radius: .3em;
    background-color: var(--gray);
}
.reservation:has(div){
    padding: 3.8em 0.5em;
}
.reservation h3{
    width: 100%;
    font-size: 1.1em;
    line-height: 1.6;
    font-weight: 500;
    text-align: justify;
}
.reservation h3 span{
    float: right;
    opacity: .9;
    font-size: .9em;
    font-weight: 400;
}
.reservation h3 ul{
    list-style: none;
    font-size: .8em;
    font-weight: 300;
    margin-top: .7em;
    display: flex;
    gap: .3em;
    flex-wrap: wrap;
}

.reservation h3 ul li{
    width: max-content;
    padding: .4em;
    background-color: #444;
    border-radius: .4em;
}

.reservation button{
    width: 100%;
}
.reservation:empty{
    height: 9em;
}
.reservation:is(.loading)::before{
        content: '';
        display: block;
        position: absolute;
        left: -150px;
        top: 0;
        height: 100%;
        width: 150px;
        background: linear-gradient(to right, transparent 0%, rgba(232, 232, 232,.3) 50%, transparent 100%);
        animation: load 1.5s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}

.edit-button{
    margin-top: 1rem;
    width: 100%;
    padding-block: .6em;
    background-color: transparent;
    color: white;
    font-size: .9em;
    font-weight: 200;
    letter-spacing: 1px;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    font-family: 'Orbitron';
    border: 1.3px solid grey;
    border-radius: .4em;
    transition: .15s linear;
}
.edit-button:hover{
    background-color: rgba(137, 137, 137, .15);
}
.edit-button:active{
    background-color: rgba(137, 137, 137, .35);
    transition: .08s linear;
}

@keyframes load {
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
}