.floating-wa-icon{
    position: fixed;
    bottom: 5%;
    right: 5%;
    font-size: 1.5em;
    padding: .3em;
    background-color: rgb(0, 253, 0);
    color: var(--white);
    stroke: var(--white);
    fill: var(--white);
    line-height: 0;
    border-radius: 50%;
    width: max-content;
    z-index: 20;
}
.floating-wa-icon svg{
    color: var(--white);
}