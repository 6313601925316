.loading-screen{
    width: 100vw;
    height: 100vh;
    left: 0;
    z-index: 100;
    position: fixed;
}
.logo-wrapper{
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    padding-left: 3em;
}
.loading-screen svg{
    width: 10em;
    position: absolute;
    left: 50%;
    top: 46%;
    height: auto;
    transform: translate(-50%, -50%);
    color: var(--red);
    stroke-width: 7px;
}
.loading-screen svg:nth-child(2){
  z-index: -10;
  color: rgba(128, 128, 128, .3);
}
.loading-screen svg path{
    fill: transparent;
}
.loading-screen svg path {
    color: inherit;
    stroke-width: 5;
    filter: drop-shadow(0 13px 1px currentColor);
  }
  .loading-screen svg:nth-child(1) path{
    stroke-dasharray: var(--length); 
    stroke-dashoffset: var(--length); 
    animation: draw 3s ease forwards;
  }
  .loading-screen path:nth-child(2){
    --length: 1599.55;
  }
  .loading-screen path:first-child{
    --length: 940.262;
  }
@keyframes draw {
    from {
      stroke-dashoffset: var(--length); 
    }
    to {
      stroke-dashoffset: 0;
    }
  }