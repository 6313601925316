
.form-header{
    font-size: 1.6em;
    letter-spacing: 1px;
    margin-block: 5rem 1rem;
    text-align: center;
    width: 100%;
    font-family: 'Orbitron', sans-serif;
    }
    .form-header span{
        color: var(--red);
    }
    .guest-page form{
        width: 25em;
        margin-inline: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    color: var(--white);
    }
    .guest-page form input, .PhoneInput input, .PhoneInput select{
    background-color: var(--white);
    font-family: 'Poppins', sans-serif;
    color: rgba(17, 17, 17, .9);
    border: none;
    outline: none;
    padding: .4em .8em;
    margin-block: 1rem;
    height: max-content;
    width: 100%;
    border-radius: .5em;
    }
    .guest-page .PhoneInputCountryIcon.PhoneInputCountryIcon{
        width: 0;
        height: auto;
    }
    .guest-page .PhoneInput select{
        width: 100%;
    }
    .guest-page .PhoneInputCountry{
        display: flex;
        flex-direction: row;
        row-gap: .7em;
        align-items: center;
    }
    .guest-page .PhoneInput > .PhoneInputCountry{
        width: 45%;
    }
    .guest-page .PhoneInput{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .guest-page form input{
    width: 45%;
    }
    .guest-page .cta-btn-123{
        width: 100%;
        border-radius: .5em;
    }
    .guest-page .PhoneInput{
        width: 100%;
    }
    
    .guest-page .double-input{
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: .5em;
    }
    .guest-page .PhoneInput{
        gap: .7em;
    }
    .guest-page form button{
        text-align: center;
        display: flex;
        justify-content: center;
    }
    
    @media (max-width: 820px){
        .guest-page form{
            width: 90%;
        }
    }