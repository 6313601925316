.navbar{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    height: 100vh;
    display: flex;
    flex-direction: row;
    transform: translateX(-4em) translateZ(0);
    width: max-content;
    background-color: var(--gray);
    transition: .2s linear transform;
font-size: clamp(1em, 1.1vw, 1.5em);
}

.navbar .nav{
    width: 4em;
    overflow: hidden;
    border-right: 1px solid rgba(105, 105, 105, .8);
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    transition: .2s linear;
}
.navbar .logo{
    height: 3.5em;
    width: auto;
    filter: drop-shadow(0 0 15px var(--red));
}

.navbar .logo path{
    fill: transparent !important;
    stroke: var(--red);
    stroke-width: 11px;
}

.navbar.toggled{
    transform: translateX(0%) translateZ(0);
}
.navbar .toggle-bar{
    width: max-content;
    background-color: var(--gray);
    border: none;
    color: var(--dark-white);
    cursor: pointer;
    padding: .5em;
}
.navbar .toggle-bar svg{
    transition: .2s linear;
}
.navbar .toggle-bar.flipped svg{
    transform: rotate(180deg);
}
.nav-links{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    flex: .6;
}
.nav-link svg, .logout-btn svg{
    filter: drop-shadow(0 0 .1px transparent);
    transition: .2s linear;
}
.nav-link{
    font-size: 1.6em;
    color: var(--white);
}
.svg-stroke path{
    stroke: currentColor;
}
.nav-link:hover svg,.logout-btn:hover svg{
    filter: drop-shadow(0 0 6px currentColor);
}
.active svg,.logout-btn svg{
    color: var(--red);
    filter: drop-shadow(0 0 2px currentColor);
}
.logout-btn{
    font-size: 1.6em;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

@media (min-width: 1600px) {
.navbar {
font-size: 1.4em;
transform: translateX(-5em) translateZ(0);
}
.navbar .nav{
    width: 5em;
}
.navbar .toggle-bar svg{
    font-size: 2em;
}
}