

.number-input input::-webkit-outer-spin-button,
.number-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.number-input{
    position: relative;
    width: 45%;
    margin-block: 1em;
    padding: .2em;
    border-radius: .3em;
    overflow: hidden;
    background-color: var(--gray);
}
.number-input input {
  -moz-appearance: textfield;
  padding: .4em;
  background-color: transparent;
  border: none;
  outline: none;
    color: var(--white);
}
.number-input button{
    position: absolute;
    right: 0;
    padding: .2em;
    line-height: 0;
    height: 50%;
    font-size: .6em;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: var(--white);
}
.number-input button:hover{
    background-color: rgba(128, 128, 128, 0.502);
}
.number-input button{
    top: 0;
    bottom: unset;
}
.number-input button:last-child{
    bottom: 0;
    top: unset;
}