.expired-popup{
    position: fixed;
    left: 50%;
    top: 30%;
    font-size: .9em;
    text-align: center;
    z-index: 500;
    color: var(--white);
    transform: translate(-50%, -50%);
    width: 20em;
    background-color: var(--gray);
    padding: 1.5em;
    border-radius: .4em;
}
.expired-popup h1{
    font-size: 1.3em;
    font-weight: 400;
}
@media screen and (max-width: 820px){
    .expired-popup h1{
        font-size: 1.2em;
    }
}