
.App{
background-color: var(--black);
width: 100%;
height: max-content;
min-height: 100vh;
display: flex;
justify-content: space-between;
flex-direction: row;
font-size: clamp(1em, 1vw, 1.5em);
user-select: none;
-webkit-user-select: none;
-moz-user-select: none;
}
@media (min-width: 1600px) {
.App {
font-size: clamp(1.1em, 1.1em, 1.5em);
}
}

.page{
    width: 100%;
}
.with-nav{
    padding-left: 3em;
}

.cta-btn-123{
    color: var(--white);
    padding: .5em 1em;
    font-family: 'Orbitron', sans-serif;
    display: block;
    width: max-content;
    margin-inline: auto;
    margin-top: 1rem;
    letter-spacing: .8px;
    font-size: .9em;
    border-radius: .3em;
    border: 1px solid transparent;
    cursor: pointer;
    transition: .2s linear;
}
.cta-btn-123.left{
    margin-inline: unset;
}
@media (hover:hover){
.cta-btn-123:hover{
    border: 1px solid var(--white);
}
.cta-btn-123:active{
    box-shadow: 0 0 10px 0 #eeeeee93;
}}
@media (hover:none){
.cta-btn-123:active{
    border: 1px solid var(--white);
    box-shadow: 0 0 10px 0 #eeeeee93;
}
}
.cta-btn-123:is(a){
    text-decoration: none;
}

.cta-btn-small{
    color: var(--white);
    padding: .2em .7em;
    font-size: .8em;
    font-family: 'Poppins', sans-serif;
    display: block;
    width: max-content;
    position: relative;
    margin-inline: auto;
    margin-block: .3rem;
    letter-spacing: .8px;
    border-radius: .3em;
    border: none;
    border: 1.4px solid transparent;
    cursor: pointer;
    transition: .1s linear;
}
.cta-btn-small:hover{
    border-color: var(--white);
}

.login-page{
    background-color: var(--black);
    height: max-content;
    min-height: 100vh;
    width: 100%;
}
.red{
    background-color: var(--red);
}

.black{
    background-color: var(--black);
}

.white{
    background-color: var(--white);
}

.dark{
    color: var(--black);
}
.light{
    color: var(--white);
}

.page-title{
    display: flex;
    align-items: center;
    font-size: 1.3em;
    font-weight: 400;
    color: var(--white);
    letter-spacing: .05em;
    width: 100%;
    font-family: 'Orbitron', sans-serif;
    padding-block: 1em;
    gap: .4em;
}
.page-title svg{
    color: var(--red);
}
.page-title:has(>button){
    width: 90%;
    justify-content: space-between;
}
.page-title svg.hasPath path{
    stroke: currentColor !important;
}

@media (max-width:820px){  
.with-nav{
    padding-left: 2.5em;
}
.page-title span{
    font-size: .4em;
}
}
input{
    font-family: 'Poppins', sans-serif;
}