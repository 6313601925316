.not-found{
    background-color: var(--black);
    width: 100%;
    height: 100vh;
    padding-top: 5rem;
font-family: 'Orbitron', sans-serif;
}
.wrapper-404{
margin-inline: auto;
width: max-content;
text-align: center;
}

.fourofour{
color: var(--red);
font-size: 3.8em;
font-weight: 700;
letter-spacing: .1em;
}

.not-found-header{
    color: var(--white);
    font-size: 1.2em;
    margin-top: .3rem;
    font-weight: 400;
    letter-spacing: .1em;
}

.wrapper-404 p{
    font-family: 'Poppins', sans-serif;
    margin-top: .5rem;
    color: var(--white);
    opacity: .9;
    font-size: .9em;
    font-weight: 200;
}
.wrapper-404 a{
    background-color: var(--red);
    color: var(--white);
    padding: .5em 1em;
    display: block;
    width: max-content;
    margin-inline: auto;
    margin-top: 1rem;
    letter-spacing: .8px;
    text-decoration: none;
    border-radius: .13em;
    border: 1px solid transparent;
    transition: .2s linear;
}
.wrapper-404 a:hover{
    border: 1px solid var(--white);
}
.wrapper-404 a:active{
    box-shadow: 0 0 10px 0 #eeeeee93;
}