.back-btn{
     display: inline-block;
     background-color: var(--gray);
     border: none;
     font-size: 1em;
     line-height: 0;
     padding: .4em;
     border-radius: .5em;
     cursor: pointer;
     color: var(--red);
     transition: .1s linear;
}
.back-btn:hover{
    opacity: .9;
}
.back-btn:active{
    opacity: .7;
    transform: scale(.9);
}
.fields{
    width: 91%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding-bottom: 1em;
}
.field{
    width: 100%;
    color: var(--white);
    padding: .4em;
    border-radius: .2em;
    background-color: var(--gray);
}
.field h2{
    font-size: 1.1em;
    font-weight: 400;
    margin-bottom: .7em;
}
.field input{
    background-color: rgb(45, 54, 66);
    border: none;
    width: 11em;
    font-family: 'Poppins', sans-serif;
    font-size: .8em;
    display: block;
    outline: none;
    color: var(--white);
    padding: .4em .5em;
    border-radius: .4em;
    opacity: .4;
}
.field:has(.form-err) input{
margin-bottom: .5em;
}
.inputs{
    display: flex;
    gap: 1em;flex-wrap: wrap;
}
.field input:not():focus{
    box-shadow: 0 0 0 3px rgba(213, 4, 8, .4);
}
.field input[type=email]{
    width: 16em;
}
.PhoneInput{
width: 11em;
flex-direction: row-reverse;
gap: .4em;
}
.edited .field input{
    opacity: 1;
}
.edit-buttons{
    display: flex;
    width: 20em;
    margin-inline: auto;
}
.field .edit-buttons{
    margin-inline: unset;
}

.admin-password-form{
    display: flex;
    flex-direction: column;
    width: 20em;
    margin: 6em auto 0;
    justify-content: center;
    align-items: center;
}
.admin-password-form input{
    width: 90%;
    padding: .5em;
    border: none;
    background-color: var(--white);
    outline: none;
    border-radius: .3em;
}
.show-password{
    width: max-content;
    height: max-content;
    font-size: 1em;
    line-height: 0;
    display: inline-block;
    border-radius: 50%;
    cursor: pointer;
    color: gray;
    opacity: .8;
    background-color: transparent;
    border: none;
    padding: .2em;
    transition: .1s linear background-color;
}
.show-password:hover{
    background-color: var(--gray);
}
.password-box{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.ban-btn{
    width: max-content;
    padding: .6em;
    cursor: pointer;
    background-color: var(--red);
    border: 3px solid var(--gray);
    border-radius: .4em;
    color: var(--white);
    margin-block: 1em;
    display: block;
}