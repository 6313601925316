.back-btn{
    display: inline-block;
    background-color: var(--gray);
    border: none;
    margin-left: 0;
    margin-top: .5em;
    font-size: 1.1em;
    line-height: 0;
    padding: .4em;
    border-radius: .5em;
    cursor: pointer;
    color: var(--red);
    transition: .1s linear;
}
.back-btn:hover{
   opacity: .9;
}
.back-btn:active{
   opacity: .7;
   transform: scale(.9);
}