.time-difference-input{
    width: 30%;
    margin-top: 1em;
}
.time-difference-input h2{
    font-size: 1em;
    color: var(--white);
    font-weight: 400;
}
.change-queuelist-pass{
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2em;
    justify-content: space-between;
}